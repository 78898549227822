* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Gilroy', sans-serif;
    user-select: none;
}

@font-face {
    font-family: 'Gilroy';
    src: url('./Gilroy-Black.woff') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body #dark {
    background: #38004c !important;
    padding: 10px
}

body #light {
    background: #F0DCFF !important;
    padding: 10px
}

#light #text {
    color: var(--primary-i-vr, #7F1DFF) !important
}

#dark #text {
    color: #FFF !important
}

.mt-6 {
    margin-top: 100px !important
}

#light #bg {
    background: #EBD1FF !important
}

#light #bgg {
    background: #7F1DFF !important
}

#dark #bg {
    background-color: var(--purple) !important
}

:root {
    --blue: #7f1dff !important;
    --purple: #1f0037 !important;
    --lblue: #3C0089 !important
}

.over {
    overflow-x: hidden !important;
    overflow-y: hidden !important
}

.back-purple {
    background-color: var(--purple)
}

a {
    text-decoration: none !important;
    color: #FFF !important
}

.c-lblue {
    color: var(--lblue) !important
}

#light .main {
    background-color: #fff
}

#dark .main {
    background-color: #38004c
}

.jt {
    text-align: justify
}

.c-blue {
    color: var(--blue) !important
}

.back-blue {
    background-color: var(--blue)
}

#dark .button-blue {
    border-radius: 20.625px;
    background: var(--primary-i-vr, #7F1DFF) !important;
    box-shadow: 6.4000000953674px 6.4000000953674px 8.8000001907349px 0 #0003 -3.2000000476837px -4.8000001907349px 8px 0 #ffffff1a -4px -2.4000000953674px 4px 0 #00000054 inset, 3.2000000476837px 3.2000000476837px 4px 0 #fff3 inset
}

#light .button-blue {
    border-radius: 20.625px;
    background: var(--primary-i-vr, #4d2c75) !important;
    box-shadow: 6.4000000953674px 6.4000000953674px 8.8000001907349px 0 #0003 -3.2000000476837px -4.8000001907349px 8px 0 #ffffff1a -4px -2.4000000953674px 4px 0 #00000054 inset, 3.2000000476837px 3.2000000476837px 4px 0 #fff3 inset
}


.fs-80 {
    font-size: 80px !important;
    font-weight: 900 !important
}

.fs-70 {
    font-size: 70px !important;
    font-weight: 900 !important;
}

.fs-85 {
    font-size: 90px !important;
    font-weight: 900 !important
}

.sec-color {
    background: var(--purple);
    border-radius: 60px
}

.navbar {
    background: linear-gradient(137deg, #3C0089 0%, #9747FF 100%);
    box-shadow: #32325d40 0 50px 100px -20px, #0000004d 0 30px 60px -30px, #0a254059 0 -2px 6px 0 inset
}

.navbar-toggler-icon {
    background-image: url(../public/assets/img/menu.webp) !important
}

.t-shadow {
    text-shadow: 23px 22px 27px rgba(0, 0, 0, 0.33), -6px -4px 18px rgba(255, 255, 255, 0.33);
}

.styles-module_whatsappButton__tVits {
    right: 2.8rem !important;
}

.nav-item a.active {
    border-radius: 13.408px !important;
    background: #FFF !important;
    color: var(--blue) !important;
    padding: 8px 10px;
    font-weight: 800 !important;
    box-shadow: 6.4000000953674px 6.4000000953674px 8.8000001907349px 0 #0003 -3.2000000476837px -4.8000001907349px 8px 0 #ffffff1a -4px -2.4000000953674px 4px 0 #00000054 inset, 3.2000000476837px 3.2000000476837px 4px 0 #fff3 inset !important
}

.nav-item a:hover {
    box-shadow: #32325d40 0 30px 60px -12px inset, #0000004d 0 18px 36px -18px inset !important
}

.nav-item a {
    border-radius: 13.408px !important;
    background: var(--blue) !important;
    color: #FFF !important;
    padding: 8px 10px;
    font-weight: 800 !important;
    box-shadow: 6.4000000953674px 6.4000000953674px 8.8000001907349px 0 #0003 -3.2000000476837px -4.8000001907349px 8px 0 #ffffff1a -4px -2.4000000953674px 4px 0 #00000054 inset, 3.2000000476837px 3.2000000476837px 4px 0 #fff3 inset !important
}

.checkbox-wrapper-54 input[type="checkbox"] {
    visibility: hidden;
    display: none
}

.checkbox-wrapper-54 {
    margin-top: 6px !important
}

.checkbox-wrapper-54 *,
.checkbox-wrapper-54 ::after,
.checkbox-wrapper-54 ::before {
    box-sizing: border-box
}

.checkbox-wrapper-54 .switch {
    --width-of-switch: 3.5em;
    --height-of-switch: 2em;
    --size-of-icon: 1.4em;
    --slider-offset: .3em;
    position: relative;
    width: var(--width-of-switch);
    height: var(--height-of-switch);
    display: inline-block
}

.checkbox-wrapper-54 .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f4f4f5;
    transition: .4s;
    border-radius: 30px
}

.checkbox-wrapper-54 .slider:before {
    position: absolute;
    content: "";
    height: var(--size-of-icon, 1.4em);
    width: var(--size-of-icon, 1.4em);
    border-radius: 20px;
    left: var(--slider-offset, 0.3em);
    top: 50%;
    transform: translateY(-50%);
    background: linear-gradient(40deg, var(--blue), var(--blue) 70%);
    transition: .4s
}

.checkbox-wrapper-54 input:checked+.slider {
    background-color: #303136
}

.checkbox-wrapper-54 input:checked+.slider:before {
    left: calc(100% - (var(--size-of-icon, 1.4em) + var(--slider-offset, 0.3em)));
    background: #303136;
    box-shadow: inset -3px -2px 5px -2px var(--blue), inset -10px -4px 0 0 var(--blue)
}

::-webkit-scrollbar {
    width: 12px
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(#3C0089, #FFF);
    border-radius: 10px
}

::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(#3C0089, #9747FF)
}



.footer-form .SubscribeBtn {
    padding: 8px 98px;
    border: 2px solid #7F1DFF;
    color: #fff;
    font-weight: 600;
    border-radius: 39px;
    background: var(--primary-i-vr, #7F1DFF);
    box-shadow: 8px 8px 11px 0 #0003 -4px -6px 10px 0 #ffffff1a -5px -3px 5px 0 #00000054 inset, 4px 4px 5px 0 #fff3 inset
}

.sect-color {
    border-radius: 104px 104px 104px 104px;
    background: linear-gradient(165deg, #7f1dff8f 1.42%, #960dca3d 33.65%, #9f06b61f 50.83%, #a800a100 65.42%)
}

.home-sec-3 .card-color {
    border-radius: 31px;
    background: var(--primary-i-vr, #7F1DFF);
    padding: 10px;
    box-shadow: 8px 8px 11px 0 #0003 -4px -6px 10px 0 #ffffff1a -5px -3px 5px 0 #00000054 inset, 4px 4px 5px 0 #fff3 inset
}

.home-sec-5 .card-color {
    border-radius: 31px;
    background: var(--primary-i-vr, #7F1DFF);
    padding: 10px;
    margin-right: 20px
}

.home-sec-3 .card img.card-img {
    border-radius: 10px;
    background: var(--dark-i-vr, #3C0089);
    box-shadow: 8px 8px 11px 0 #0003 -4px -6px 10px 0 #ffffff1a -5px -3px 5px 0 #00000054 inset, 4px 4px 5px 0 #fff3 inset;
    width: 15%;
    padding: 8px
}

.home-sec-3 .card img.card-img-1 {
    border-radius: 10px;
    background: var(--secondary-light-green, #E2EEEC);
    box-shadow: 8px 8px 11px 0 #0003 -4px -6px 10px 0 #ffffff1a -5px -3px 5px 0 #00000054 inset, 4px 4px 5px 0 #fff3 inset;
    width: 15%;
    padding: 8px
}

.home-sec-3 .blog {
    border-radius: 33px;
    background: #FFF;
    padding: 8px;
    height: 600px;
    box-shadow: 8px 8px 11px 0 #0003 -4px -6px 10px 0 #ffffff1a -5px -3px 5px 0 #00000054 inset, 4px 4px 5px 0 #fff3 inset
}

.sect-color-1 {
    border-radius: 129px;
    background: linear-gradient(160deg, #7f1dff8f 1.42%, #960dca3d 33.65%, #9f06b61f 50.83%, #a800a100 65.42%)
}

.home-sec-6 .image_wrapper img {
    clip-path: polygon(15% 0, 100% 0%, 85% 100%, 0% 100%);
}

.home-sec-7 .image_wrapper img {
    clip-path: polygon(15% 0, 100% 0%, 85% 100%, 0% 100%);
}

.home-sec-9 .image_wrapper img {
    clip-path: polygon(15% 0, 100% 0%, 85% 100%, 0% 100%);
}

button.whats {
    position: fixed;
    top: 42.5%;
    right: 0
}

button.insta {
    position: fixed;
    top: 62.5%;
    right: 0
}

button.face {
    position: fixed;
    top: 32.5%;
    right: 0
}

button.link {
    position: fixed;
    top: 52.5%;
    right: 0
}

button.twit {
    position: fixed;
    top: 72.5%;
    right: 0
}

button.discord {
    position: fixed;
    top: 82.5%;
    right: 0
}

.sec-3 .news {
    position: relative;
    max-width: 380px;
    flex: 0 0 400px
}

.sec-3 .news .Email {
    width: 100%;
    position: relative;
    height: 50px;
    padding: 12px 35% 12px 12px;
    border-radius: 50px;
    border: 2px solid #7F1DFF;
    margin-bottom: 0
}

.sec-3 .news .SubscribeBtn {
    position: absolute;
    right: 0;
    padding: 11.5px 22px;
    border: 2px solid #7F1DFF;
    color: #fff;
    font-weight: 600;
    border-radius: 39px;
    background: var(--primary-i-vr, #7F1DFF);
    box-shadow: 8px 8px 11px 0 #0003 -4px -6px 10px 0 #ffffff1a -5px -3px 5px 0 #00000054 inset, 4px 4px 5px 0 #fff3 inset
}

.home-sec-7 .news {
    position: relative;
    max-width: 380px;
    flex: 0 0 400px
}

.home-sec-7 .news .Email {
    width: 100%;
    position: relative;
    height: 50px;
    padding: 12px 35% 12px 12px;
    border-radius: 50px;
    border: 2px solid #7F1DFF;
    margin-bottom: 0
}

.home-sec-7 .news .SubscribeBtn {
    position: absolute;
    right: 0;
    padding: 11.5px 22px;
    border: 2px solid #7F1DFF;
    color: #fff;
    font-weight: 600;
    border-radius: 39px;
    background: var(--primary-i-vr, #7F1DFF);
    box-shadow: 8px 8px 11px 0 #0003 -4px -6px 10px 0 #ffffff1a -5px -3px 5px 0 #00000054 inset, 4px 4px 5px 0 #fff3 inset
}

.home-sec-6 .news {
    position: relative;
    max-width: 380px;
    flex: 0 0 400px
}

.home-sec-6 .news .Email {
    width: 100%;
    position: relative;
    height: 50px;
    padding: 12px 35% 12px 12px;
    border-radius: 50px;
    border: 2px solid #7F1DFF;
    margin-bottom: 0
}

.home-sec-6 .news .SubscribeBtn {
    position: absolute;
    right: 0;
    padding: 11.5px 22px;
    border: 2px solid #7F1DFF;
    color: #fff;
    font-weight: 600;
    border-radius: 39px;
    background: var(--primary-i-vr, #7F1DFF);
    box-shadow: 8px 8px 11px 0 #0003 -4px -6px 10px 0 #ffffff1a -5px -3px 5px 0 #00000054 inset, 4px 4px 5px 0 #fff3 inset
}

.home-sec-7 .news {
    position: relative;
    max-width: 380px;
    flex: 0 0 400px
}

.home-sec-7 .news .Email {
    width: 100%;
    position: relative;
    height: 50px;
    padding: 12px 35% 12px 12px;
    border-radius: 50px;
    border: 2px solid #7F1DFF;
    margin-bottom: 0
}

.home-sec-7 .news .SubscribeBtn {
    position: absolute;
    right: 0;
    padding: 11.5px 22px;
    border: 2px solid #7F1DFF;
    color: #fff;
    font-weight: 600;
    border-radius: 39px;
    background: var(--primary-i-vr, #7F1DFF);
    box-shadow: 8px 8px 11px 0 #0003 -4px -6px 10px 0 #ffffff1a -5px -3px 5px 0 #00000054 inset, 4px 4px 5px 0 #fff3 inset
}
.home-sec-8 .news {
    position: relative;
    max-width: 380px;
    flex: 0 0 400px
}

.home-sec-8 .news .Email {
    width: 100%;
    position: relative;
    height: 50px;
    padding: 12px 35% 12px 12px;
    border-radius: 50px;
    border: 2px solid #7F1DFF;
    margin-bottom: 0
}

.home-sec-8 .news .SubscribeBtn {
    position: absolute;
    right: 0;
    padding: 11.5px 22px;
    border: 2px solid #7F1DFF;
    color: #fff;
    font-weight: 600;
    border-radius: 39px;
    background: var(--primary-i-vr, #7F1DFF);
    box-shadow: 8px 8px 11px 0 #0003 -4px -6px 10px 0 #ffffff1a -5px -3px 5px 0 #00000054 inset, 4px 4px 5px 0 #fff3 inset
}
.home-sec-9 .news {
    position: relative;
    max-width: 380px;
    flex: 0 0 400px
}

.home-sec-9 .news .Email {
    width: 100%;
    position: relative;
    height: 50px;
    padding: 12px 35% 12px 12px;
    border-radius: 50px;
    border: 2px solid #7F1DFF;
    margin-bottom: 0
}

.home-sec-9 .news .SubscribeBtn {
    position: absolute;
    right: 0;
    padding: 11.5px 22px;
    border: 2px solid #7F1DFF;
    color: #fff;
    font-weight: 600;
    border-radius: 39px;
    background: var(--primary-i-vr, #7F1DFF);
    box-shadow: 8px 8px 11px 0 #0003 -4px -6px 10px 0 #ffffff1a -5px -3px 5px 0 #00000054 inset, 4px 4px 5px 0 #fff3 inset
}

.fs-40{
    font-size: 50px !important;
}

.home-sec-1 {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.home-sec-2 .news {
    position: relative;
    max-width: 380px;
    flex: 0 0 400px
}

.home-sec-2 .news .Email {
    width: 100%;
    position: relative;
    height: 50px;
    padding: 12px 35% 12px 12px;
    border-radius: 50px;
    border: 2px solid #7F1DFF;
    margin-bottom: 0
}

.home-sec-2 .news .SubscribeBtn {
    position: absolute;
    right: 0;
    padding: 11.5px 22px;
    border: 2px solid #7F1DFF;
    color: #fff;
    font-weight: 600;
    border-radius: 39px;
    background: var(--primary-i-vr, #7F1DFF);
    box-shadow: 8px 8px 11px 0 #0003 -4px -6px 10px 0 #ffffff1a -5px -3px 5px 0 #00000054 inset, 4px 4px 5px 0 #fff3 inset
}

.index {
    position: relative;
    z-index: 9 !important
}

.fs-10 {
    font-size: 14px !important
}

.w {
    width: 50% !important
}

.wt {
    width: 85% !important
}

.slick-prev {
    left: 70% !important
}

.slick-prev:before,
.slick-next:before {
    font-size: 40px !important;
    position: absolute;
    top: -186px;
}


.slick-next {
    right: 110px !important
}

.download-sec-1 .news {
    position: relative;
    max-width: 380px;
    flex: 0 0 400px
}

iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.content-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    /* Adjust text color as needed */
}

.download-sec-1 .news .Email {
    width: 100%;
    position: relative;
    height: 50px;
    padding: 12px 35% 12px 12px;
    border-radius: 50px;
    border: 2px solid #7F1DFF;
    margin-bottom: 0
}

.download-sec-1 .news .SubscribeBtn {
    position: absolute;
    right: 0;
    padding: 11px 28px;
    border: 2px solid #7F1DFF;
    color: #fff;
    font-weight: 600;
    border-radius: 39px;
    background: var(--primary-i-vr, #7F1DFF);
    box-shadow: 8px 8px 11px 0 #0003 -4px -6px 10px 0 #ffffff1a -5px -3px 5px 0 #00000054 inset, 4px 4px 5px 0 #fff3 inset
}

.why-sec-2 .news {
    position: relative;
    max-width: 480px;
    flex: 0 0 500px
}

.why-sec-2 .news .Email {
    width: 100%;
    position: relative;
    height: 50px;
    padding: 12px 35% 12px 12px;
    border-radius: 50px;
    border: 2px solid #7F1DFF;
    margin-bottom: 0
}

.why-sec-2 .news .SubscribeBtn {
    position: absolute;
    right: 0;
    padding: 11px 28px;
    border: 2px solid #7F1DFF;
    color: #fff;
    font-weight: 600;
    border-radius: 39px;
    background: var(--primary-i-vr, #7F1DFF);
    box-shadow: 8px 8px 11px 0 #0003 -4px -6px 10px 0 #ffffff1a -5px -3px 5px 0 #00000054 inset, 4px 4px 5px 0 #fff3 inset
}

.accordion-button::after {
    background-image: url(/public/assets/img/arrow.webp) !important
}

.accordion-button:focus {
    box-shadow: none !important;
}

@media (max-width:900px) {

    .wt {
        width: 100% !important
    }

    .button-blue {
        font-size: 12px !important;
        font-weight: 700 !important;
    }

    .download-sec-1 .mai{
        width: 80% !important;
    }

    header ul.navbar-nav {
        display: block !important
    }

    header ul.navbar-nav li {
        padding-bottom: 10px !important
    }

    .home-sec-8 p.para {
        width: 75% !important
    }

    .fs-80 {
        font-size: 50px !important
    }

    .fs-70 {
        font-size: 40px !important;
    }

    .fs-40 {
        font-size: 55px !important
    }

    .fs-85 {
        font-size: 55px !important
    }

    .home-sec-2 .row {
        margin-top: 0 !important
    }

    .t-shadow {
        text-shadow: 23px 22px 27px rgba(0, 0, 0, 0.33), -6px -4px 18px rgba(255, 255, 255, 0.33);
    }

}

@media (max-width: 500px) {

    .slick-prev:before,
    .slick-next:before {
        font-size: 30px !important;
        top: 0px;
    }

    .home-sec-1 h1 {
        width: 100% !important
    }

    .download-sec-1 .mai{
        width: 75% !important;
    }

    .slick-next {
        z-index: 99 !important;
        right: 0px !important
    }

    .tecenter {
        text-align: center !important;
    }

    .m-to {
        margin: auto !important;
    }

    .slick-prev {
        z-index: 99 !important;
        left: -9% !important
    }

    .hidden {
        display: none
    }

    .t-shadow {
        text-shadow: 23px 22px 27px rgba(0, 0, 0, 0.33), -6px -4px 18px rgba(255, 255, 255, 0.33);
    }

    .mT {
        margin-top: 0 !important
    }

    .home-sec-5 .card-color {
        margin-right: 0 !important
    }

    /* .slick-next {
        right: -8px !important
    } */

    .fs-80 {
        font-size: 40px !important
    }

    .fs-70 {
        font-size: 30px !important;
    }

    .w {
        width: 100% !important
    }

    .fs-10 {
        font-size: 12px !important
    }

    .center {
        text-align: center !important
    }

    .container {
        width: 330px !important
    }

    .fs-40{
        font-size: 55px !important;
    }

    .fs-85 {
        font-size: 45px !important
    }

    .home-sec-2 .row {
        margin-top: 20px !important
    }

    .home-sec-8 p.para {
        width: 100% !important
    }

    header ul.navbar-nav {
        display: block !important
    }

    header ul.navbar-nav li {
        padding-bottom: 10px !important
    }

    .why-sec-1 .I1 {
        width: 100% !important
    }
}